.socials {
	&__item {
		margin: 0 0 10px;
		width: 50px;
		height: 50px;
		background-color: $white;
		padding: 10px;
		align-items: center;
		justify-content: center;
		transition: background-color .25s;

		path,
		rect {
			transition: fill .25s;
			fill: $black; }

		&:last-child {
			margin: 0; }

		img,
		svg {
			margin: 0 auto;
			display: block; }

		&:hover {
			background-color: $violet;
			path,
			rect {
				fill: $white; } }

		&.is-black {
			background-color: $black;

			path,
			rect {
				fill: $white; }

			&:hover {
				background-color: $violet; } } } }
