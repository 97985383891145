.lang {
	&__list {
		justify-content: flex-end;
		@include r(767) {
			justify-content: flex-start; }
		li {
			margin-left: 20px;
			opacity: .6;
			&.is-active {
				opacity: 1; }
			&:first-child {
				margin-left: 0; } }
		a {
			font-size: 18px;
			@include regular;
			@include r(1365) {
				font-size: 16px; } } } }


