.topic {
	padding: 60px 0 160px;
	background-color: $white;
	position: relative;

	.triangle {
		z-index: 0;
		&.is-second {
			top: 50%;
			transform: translateY(-50%); }
		&.is-third {
			bottom: 15%; } }


	&__title {
		margin-bottom: 35px;

		h1,
		h2 {
			font-size: 36px; }

		&.is-service {
			margin-bottom: 120px; } }

	&__panel {
		justify-content: flex-start;
		flex-wrap: wrap; }

	&__date {
		margin: 0 30px 20px 0;
		padding: 0 0 0 30px;
		position: relative;
		img {
			width: 24px;
			height: 24px;
			position: absolute;
			left: 0;
			top: 0;
			display: block; }
		p {
			font-size: 14px;
			line-height: 24px; } }

	&__tags {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		margin: 0 35px 20px 0; }

	&__container {
		max-width: 920px;
		width: 100%;
		margin: 0 auto; }

	&__socials {
		padding-top: 80px;
		justify-content: flex-start;
		flex-wrap: wrap;

		.socials__item {
			margin: 0 20px 20px 0;
			&:last-child {
				margin: 0; } } } }
