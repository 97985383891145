.arrow-prev,
.arrow-next {
	pointer-events: auto;
	display: inline-block;
	padding: 5px 0;
	cursor: pointer;
	width: 25px; }
.arrow-prev {
	margin-right: 40px; }
.arrow-next {
	transform: rotate(180deg) translateY(3.5px); }
