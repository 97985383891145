.tag {
	position: relative;
	margin: 0 30px 0 0;
	display: inline-block;
	padding: 0 0 0 30px;
	&:last-child {
		margin-right: 0; }

	img,
	svg {
		position: absolute;
		width: 24px;
		height: 24px;
		display: block;
		top: 0;
		left: 0; }

	p {
		font-size: 14px;
		line-height: 24px; } }
