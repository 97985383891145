.contact {
	&__phone {
		&.is-white {
			margin: 0 60px 20px 0;
			a {
				color: rgba($white, .5); } }

		a {
			transition: color .25s;
			&:hover {
				color: $violet; } } }

	&__info {
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		@include r(767) {
			flex-wrap: nowrap;
			flex-direction: column;
			align-items: flex-start; } }

	&__address p,
	&__phone a {
		font-size: 16px;
		line-height: 1.2; }

	&__address {
		margin: 0 60px 20px 0;
		p {
			color: rgba($white, .5); } }

	&__social {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 20px 0 0;
		&:last-child {
			margin: 0; }
		path,
		rect {
			fill: rgba($white, .5);
			transition: fill .25s; }

		svg {
			&:hover {
				path,
				rect {
					fill: $violet; } } }

		&s {
			margin: 0 60px 20px 0;
			&:last-child {
				margin: 0 0 20px 0; } } } }
