.footer {
	position: relative;
	overflow: hidden;
	z-index: 0;

	&__container {
		align-items: stretch;
		@include r(1023) {
			flex-direction: column-reverse; } }

	&__site-info,
	&__form-wr {
		width: 100%;
		position: relative;
		z-index: 1;
		&::before {
			content: "";
			position: absolute;
			z-index: -1;
			width: 100vw;
			height: 100%;
			top: 0;
			display: block; } }

	&__site-info {
		max-width: 66.6667%;
		padding: 58px 50px 60px 0;
		@include r(1365) {
			padding: 58px 30px 60px 0; }
		@include r(1279) {
			max-width: 60%; }
		@include r(1023) {
				padding: 50px 0;
				max-width: 100%; }
		&::before {
			right: 0;
			background-color: $white-grey;
			@include r(1023) {
				right: -40px; }
			@include r(767) {
				right: -20px; } } }

	&__form {
		&-wr {
			max-width: 33.3333%;
			padding: 58px 0 60px 130px;
			@include r(1365) {
				padding: 58px 0 60px 50px; }
			@include r(1279) {
				max-width: 40%; }
			@include r(1023) {
				padding: 50px 0;
				max-width: 100%; }
			&::before {
				left: 0;
				background-color: $dark-black;
				@include r(1023) {
					left: -40px; }
				@include r(767) {
					left: -20px; } } }
		&__title {
			color: $white;
			margin-bottom: 30px;
			@include bold;
			font-size: 18px; } }

	.navigation {
		margin-bottom: 30px;
		@include r(767) {
			margin-bottom: 50px; }
		&__list {
			justify-content: flex-start;
			flex-wrap: wrap;
			li {
				margin: 0 40px 20px 0;

				@include r(1365) {
					margin: 0 25px 20px 0; }

				&:last-child {
					margin: 0; } }

			a {} } }


	.contact {
		&__subscribe {
			@include r(1023) {
				max-width: 300px; } } } }
