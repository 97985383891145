.form {
	display: block;
	width: 100%;
	position: relative;

	&.is-white {
		.form__input {
			border-bottom: 1px solid $white;
			color: $white;
			font-size: 14px;
			&::placeholder {
				color: $white; } }

		.form__img {
			path,
			rect {
				fill: $white; } } }

	&__label {
		display: inline-block;
		width: 100%;
		position: relative; }

	&__placeholder {
		position: absolute;
		user-select: none;
		pointer-events: none;
		top: 5px;
		left: 0;
		line-height: 1.2;
		transition: all .25s;
		&.is-changed {
			transform: translateY(calc(-100% - 5px)); } }

	&__img {
		border: 0;
		background-color: transparent;
		position: absolute;
		display: block;
		width: 30px;
		height: 20px;
		right: 0;
		top: 5px;
		svg,
		img {
			transform: rotate(180deg); } }

	&__input {
		display: inline-block;
		width: 100%;
		background-color: transparent;
		border: 0;
		padding: 5px 40px 8px 0;
		border-bottom: 1px solid rgba($grey, .35);
		&:focus + p {
			transform: translateY(calc(-100% - 5px)); }

		&[name="tel"] {
			&:hover + p {
				transform: translateY(calc(-100% - 5px));
				font-size: 16px;
				font-family: "PT Sans", sans-serif;
				font-weight: 400;
				color: rgba(151, 151, 151, 0.75); } }

		&.is_no-valid {
			border-bottom: 1px solid tomato; } }

	&__file {
		cursor: pointer;
		&-name {
			line-height: 1.5em;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: inline-block;
			padding: 0 110px 0 30px;
			bottom: 0;
			left: 0;
			position: relative;
			img {
				position: absolute;
				width: 20px;
				height: 20px;
				top: 2px;
				left: 0;
				display: block; }
			> span:not(.form__file-close) {
				max-width: 250px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				display: inline-block; } }
		&-close {
			position: absolute;
			right: 0;
			top: 2px;
			display: block;
			width: 20px;
			height: 20px;
			cursor: pointer;
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				display: block;
				width: 17px;
				height: 1px;
				background-color: $black; }

			&::before {
				transform: rotate(45deg); }

			&::after {
				transform: rotate(-45deg); } } } }


span.is_no-valid {
	color: tomato;
	position: absolute;
	left: 0;
	top: 100%;
	width: auto;
	display: inline-block; }

.for-file {
	span.is_no-valid {
		top: calc(100% - 50px); } }
