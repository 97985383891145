.widget {
	position: fixed;
	z-index: 5;
	right: 0;
	top: calc(50% - 150px);
	transform: translateY(-50%);
	display: block;
	width: 50px;
	height: 100px;
	background-color: tomato;

	&.is-black {
		.widget__button {
			background-color: $black;
			p {
				color: $white; }
			&:hover {
				background-color: $violet; } }

		.socials__item {
			background-color: $black;
			path,
			rect {
				fill: $white; }

			&:hover {
				background-color: $violet;
				path,
				rect {
					fill: $white; } } } }

	&__button {
		transition: background-color .25s; }

	&__button {
		position: relative;
		overflow: hidden;
		width: auto;
		display: inline-block;
		width: 100%;
		height: 210px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0;
		background-color: $white;
		margin: 0 0 10px;
		cursor: pointer;

		p {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 210px;
			height: 50px;
			transform: rotate(270deg) translateX(-100%);
			transform-origin: left top;
			display: flex;
			align-items: center;
			justify-content: center;
			// height: 210px
			// width: 100%
			// display: block
			// writing-mode: vertical-lr
			// transform: translateX(-25%) rotate(180deg)
			font-size: 14px;
			@include bold;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			transition: color .25s; } }

	&__button,
	&__socials {
		&:hover {
			background-color: $violet; } }

	&__button {
		&:hover {
			p {
				color: $white; } } } }
