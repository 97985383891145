// +font-face('MuseoSansCyr-light', '../fonts/museosanscyrl-100-webfont')
// +font-face('MuseoSansCyr-medium', '../fonts/museosanscyrl-500-webfont')
// +font-face('MuseoSansCyr-black', '../fonts/museosanscyrl_0-webfont')

@mixin regular {
	font-family: 'PT Sans', sans-serif;
	font-weight: 400; }

@mixin bold {
	font-family: 'PT Sans', sans-serif;
	font-weight: 700; }


h1,h2,h3,h4,h5,h6,p {
	margin: 0;
	padding: 0;
	color: $black;
	line-height: 1.2; }

a {
	@include regular; }

p {
	font-size: 18px;
	line-height: 1.56;
	@include regular;
	color: $black;
	@include r(767) {
		font-size: 14px; } }


h1,h2,h3,h4,h5,h6 {
	@include bold;
	line-height: 1.25; }

img {
	max-width: 100%;
	display: block;
	height: auto; }

img.lazy {
	background-image: url(data:image/gif;base64,R0lGODlhCgAIAIABAN3d3f///yH5BAEAAAEALAAAAAAKAAgAAAINjAOnyJv2oJOrVXrzKQA7); }
img.lazy.loaded {
	background-image: none; }

h1,
.h1 {
	font-size: 64px;
	@include r(1023) {
		font-size: 36px; }
	@include r(767) {
		font-size: 28px; } }

h2 {
	font-size: 64px;
	@include r(1279) {
		font-size: 44px; }
	@include r(1023) {
		font-size: 36px; }
	@include r(767) {
		font-size: 28px; } }

h3 {
	font-size: 24px;
	@include r(1023) {
		font-size: 18px; } }


.typography {
	h2,
	h3,
	h4,
	h5 {
		@include bold;
		padding-top: 10px;
		margin-bottom: 20px; }

	p,
	li {
		line-height: 1.5;
		@include regular; }

	p {
		padding: 15px 0; }

	li {
		position: relative;
		padding: 0 0 10px 25px;
		&:before {
			content: "";
			position: absolute;
			left: 5px;
			top: 10px;
			width: 6px;
			height: 6px;
			display: block;
			border: 1px solid $violet;
			transform: rotate(45deg); } }

	h2 {
		font-size: 24px; }

	h3,
	p,
	li {
		font-size: 18px; }

	h4 {
		font-size: 16px; }

	img {
		width: 100%;
		margin: 20px 0; }

	iframe {
		width: 100%;
		height: 500px;
		display: block; }

	ul {
		margin-bottom: 30px; }

	button {
		margin-top: 55px; } }
