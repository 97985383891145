.for-file {

	&__wrapper {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: calc(100% - 50px);
		padding: 10px;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px dashed rgba($grey, .5);

		> img {
			margin: 0 auto 15px; } }

	.form__placeholder {
		position: relative;
		top: auto;
		left: auto;
		margin: 0 0 10px;
		text-align: center;
		font-size: 24px; }

	input {
		width: 100%;
		height: calc(100% - 40px);
		opacity: 0;
		padding: 0; } }
