.contact-us {
	padding: 70px 0 220px;
	@include r(1023) {
		padding: 70px 0 120px; }
	@include r(767) {
		padding: 60px 0 90px; }

	&__container {
		&.is-hidden {
			margin: 20px 0 0;
			display: none; } }


	&.is-inner,
	&.on-service {
		padding: 120px 0 160px;
		@include r(1023) {
			padding: 80px 0 120px; }
		@include r(767) {
			padding: 60px 0 90px; } }

	&__wrapper {
		background-color: $white;
		padding: 60px;
		align-items: stretch;
		position: relative;
		@include r(1023) {
			padding: 30px; }
		@include r(767) {
			flex-wrap: wrap; }

		&.manager {
			padding: 0;
			max-height: 700px;
			overflow: hidden;
			@include r(1023) {
				flex-wrap: wrap;
				max-height: none; } } }

	&__block {
		width: 100%;
		padding: 1.7em 0 0;

		&.is-half {
			max-width: 50%;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			@include r(767) {
				max-width: 100%;
				margin: 0 0 40px; }


			&:first-child {
				position: relative;
				padding: 0 80px 0 0;
				@include r(1023) {
					padding: 0 40px 0 0; }
				@include r(767) {
					padding: 0; }
				&::after {
					content: "";
					position: absolute;
					width: 1px;
					height: 100%;
					right: 0;
					top: 0;
					background-color: rgba($grey, .35);
					@include r(767) {
						display: none; } } }

			&:last-child {
				padding: 0 0 0 80px;
				@include r(1023) {
					padding: 0 0 0 40px; }
				@include r(767) {
					margin: 0;
					padding: 0; } }

			button {
				margin: auto 0 0;
				@include r(767) {
					padding: 15px 20px; } } }

		&.is-left {
			max-width: 35%;
			padding-right: 5em;
			position: relative;
			@include r(1023) {
				max-width: 50%; }
			@include r(767) {
				max-width: 100%;
				padding: 0;
				margin: 0 0 35px; }
			&::before {
				content: "";
				height: 100%;
				width: 2px;
				display: block;
				background-color: rgba($grey, .15);
				position: absolute;
				right: 0;
				top: 0;
				@include r(767) {
					display: none; } } }

		&.is-right {
			max-width: 75%;
			padding-left: 5em;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: space-between;
			@include r(1023) {
				max-width: 50%; }
			@include r(767) {
				max-width: 100%;
				padding: 0; }
			.contact-us__row {
				flex-grow: 0.5;
				&:first-child {
					flex-grow: 3;
					margin-bottom: 20px;
					label {
						width: 100%;
						height: 100%;
						@include r(767) {
							height: 250px; } } }

				&:last-child {
					display: flex;
					align-items: flex-end;
					justify-content: flex-start; } } }

		&.manager {
			&-left,
			&-right,
			&-center {
				max-width: 33.333%;
				width: 100%; }

			&-left {
				padding: 0;
				@include r(1023) {
					max-width: 100%; }

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					@include r(1023) {
						max-width: initial;
						height: 300px; }
					@include r(479) {
						max-width: 100%; } } }

			&-right,
			&-center {
				padding: 30px 0;
				align-self: center;
				@include r(1023) {
					max-width: 50%;
					align-self: flex-start; }
				@include r(767) {
					max-width: 100%; } }

			&-center {
				padding: 50px 30px 30px;
				@include r(1023) {
					padding: 50px; }
				@include r(767) {
					padding: 50px 10px 10px; } }

			&-right {
				padding: 50px 30px 30px 0;
				@include r(1023) {
					padding: 50px 30px 30px; }
				@include r(767) {
					padding: 50px 10px 10px; }
				.contact-us__row {
					&:first-child {
						min-height: 245px;
						display: flex;
						@include r(1023) {
							min-height: 150px; } } } } } }


	&__row {
		margin: 0 0 70px;
		@include r(767) {
			margin: 0 0 35px; }
		&:last-child {
			margin: 0; }

		&.not-robot {
			margin-bottom: 15px; }

		input,
		textarea {
			@include bold;
			font-size: 24px;
			color: rgba($black, 1);
			@include r(1023) {
				font-size: 20px; }
			&:focus + p {
				font-size: 16px;
				@include regular;
				color: rgba($grey, .75); } }

		textarea {
			resize: none;
			width: 100%;
			height: 100%;
			border: 0; }

		button {
			min-width: 260px; }

		.form__placeholder {
			color: rgba($grey, .55);
			font-size: 24px;
			@include bold;
			@include r(1023) {
				font-size: 20px; }

			&.is-changed {
				font-size: 16px;
				@include regular;
				color: rgba($grey, .75); } } }

	&__title {
		margin: 0 0 40px;
		@include r(1023) {
			margin: 0 0 25px; } }

	&__text {
		margin: 0 0 60px;
		@include r(1023) {
			margin: 0 0 30px; } }

	&__list {
		a {
			@include bold;
			margin: 0 0 5px;
			&:last-child {
				margin: 0; } } } }
