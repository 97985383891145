.crumbs {
	padding: 30px 0;

	&.is-topic {
		padding: 110px 0 30px;
		background-color: $white;

		.crumbs__container {
			max-width: 920px;
			width: 100%;
			margin: 0 auto; } }

	&__navigation {
		display: inline-block; }

	&__list {
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		@include r(767) {
			max-width: 100%;
			width: 100%; } }

	&__item {
		padding-left: 20px;
		position: relative;
		color: $grey;
		font-size: 14px;
		transition: all .25s;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 300px;

		&::before {
			content: ">";
			color: $grey;
			transform: translateX(-8px);
			display: inline-block;
			pointer-events: none;
			transition: all .25s; }

		&:first-child {
			padding-left: 0;
			&::before {
				display: none; } }

		&:last-child {
			color: $violet;
			@include r(767) {
				max-width: 150px; }
			&:hover {
				text-decoration: none;
				opacity: 1; } }

		&:hover {
			opacity: .8;
			text-decoration: underline; }

		a {
			color: inherit;
			font-size: inherit; } } }
