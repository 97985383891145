.burger {
	display: none;
	&__wrap {
		align-items: center;
		cursor: pointer;
		&.is-opened {
			.burger__menu {
				opacity: 0; }
			.burger__line {
				&:nth-child(2) {
					opacity: 0; }
				&:nth-child(1),
				&:nth-child(3) {
					top: 40%; }
				&:nth-child(1) {
					transform: rotate(45deg); }
				&:nth-child(3) {
					transform: rotate(135deg); } } } }
	&__menu {
		margin-right: 10px;
		text-transform: uppercase;
		@include regular;
		font-size: 14px;
		line-height: 2;
		transition: opacity	.25s; }
	&__box {
		width: 22px;
		height: 18px;
		position: relative; }
	&__line {
		width: 100%;
		height: 3px;
		position: absolute;
		left: 0;
		background-color: $black;
		display: block;
		&:nth-child(1) {
			top: 0px; }
		&:nth-child(2) {
			top: 7px;
			transition: all .25s; }
		&:nth-child(3) {
			top: 14px; } } }
