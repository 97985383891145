.decor {
	&-link {
		opacity: 1;
		transition: opacity	.25s;
		position: relative;
		padding-left: 16px;
		margin-top: auto;
		cursor: pointer;
		display: inline-block;

		&:hover {
			p::before {
				transform: rotate(315deg); } }

		p {
			position: relative;
			padding-left: 30px;
			color: $white;
			&::before {
				content: "";
				position: absolute;
				width: 6px;
				height: 6px;
				border: 2px solid $white;
				transform: rotate(45deg);
				top: 10px;
				left: 0;
				display: block;
				transition: transform	.55s;
				@include r(767) {
					top: 6px; } } }

		&--black {
			p {
				color: $black;
				&::before {
					border: 2px solid $black; } } } } }
