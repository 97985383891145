.navigation {
	&__list {
		&.is-white {
			a {
				color: $white; } }
		&.is-bold {
			a {
				@include bold; } }
		li {
			text-align: right;
			margin-left: 60px;
			&:first-child {
				margin-left: 0; }
			@include r(1365) {
				margin-left: 4vw; } }

		a {
			font-size: 18px;
			transition: color .25s;
			@include regular;
			@include r(1365) {
				font-size: 16px; }
			@include r(1023) {
				font-size: 18px; }
			&:hover {
				color: $violet; } } } }
