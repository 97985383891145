.header {
	padding: 8px 0;
	background-color: $white;
	position: fixed;
	z-index: 2;
	width: 100%;
	top: 0;
	left: 0;
	box-shadow: 0px 0px 2px 0px rgba(151,151,151,.35);
	&.is-opened {
		min-height: 100vh;
		@include r(1023) {
			padding: 8px 0 40px;
			.navigation {
				display: block;
				padding: 10vh 0 0; } }
		@include r(767) {
			.lang__container,
			.contact__phone {
				display: block; }
			.navigation {
				padding: 40px 0 0; } } }



	&__container {
		align-items: center;
		justify-content: flex-end;
		@include r(1023) {
			justify-content: center; }
		@include r(767) {
			flex-wrap: wrap;
			align-items: flex-start; }
		@supports(display: grid) {
			display: grid;
			grid-template-columns: 110px 5fr minmax(185px, 1fr) minmax(200px, 1fr);
			@include r(1365) {
				grid-template-columns: 90px 5fr minmax(160px, 1fr) minmax(160px, 1fr); }
			@include r(1023) {
				justify-content: initial;
				grid-template-columns: auto;
				grid-template-areas: "logo phone lang burger" "navigation navigation navigation navigation"; }
			@include r(767) {
				grid-template-areas: "logo burger" "navigation navigation" "phone phone" "lang lang"; } } }

	.logo,
	.navigation,
	.contact__phone,
	.lang__container {
		@supports(display: grid) {
			align-self: center; } }

	.logo {
		max-width: 100px;
		margin-right: auto;
		padding-right: 10px;
		@include r(1023) {
			margin-right: auto; }
		@include r(767) {
			order: 1;
			height: 65px;
			img {
				height: 100%; } }
		@supports(display: grid) {
			order: initial;
			@include r(1023) {
				grid-area: logo; }
			@include r(767) {
				max-width: 100px; } } }

	.contact__phone,
	.navigation,
	.lang {
		@supports(display: grid) {
			padding-left: 40px;
			@include r(1365) {
				padding-left: 20px; }
			@include r(767) {
				padding: 0;
				display: none; } } }


	.navigation {
		margin-right: 100px;
		@include r(1365) {
			margin-right: 4vw; }
		@include r(1023) {
			display: none; }
		@include r(767) {
			width: 100%;
			order: 3;
			margin-bottom: 90px; }
		@supports(display: grid) {
			order: initial;
			margin-right: 0;
			padding-right: 40px;
			@include r(1023) {
				padding: 6vh 0 0;
				grid-area: navigation;
				display: none; }
			@include r(767) {
				padding: 0; } }

		&__list {
			justify-content: flex-end;
			@include r(1023) {
				flex-direction: column;
				justify-content: flex-start; }
			li {
				@include r(1023) {
					margin: 0 0 5vw;
					&:last-child {
						margin: 0; } }
				@include r(767) {
					margin: 0 0 30px; } } } }



	.contact__phone {
		margin-right: 100px;
		@include r(1365) {
			margin-right: 4vw; }
		@include r(1023) {
			margin-right: 6vw; }
		@include r(767) {
			display: none;
			width: 100%;
			order: 4;
			margin-bottom: 40px; }
		@supports(display: grid) {
			margin: 0;
			padding-right: 0;
			order: initial;
			text-align: right;
			@include r(1365) {
				margin: 0; }
			@include r(1023) {
				grid-area: phone; }
			@include r(767) {
				text-align: left;
				margin-bottom: 40px;
				a {
					@include bold; } } } }


	.lang {
		&__container {
			@include r(767) {
				display: none;
				width: 100%;
				order: 5; }
			@supports(display: grid) {
				order: initial;
				justify-self: right;
				@include r(1023) {
					padding-left: 20px;
					grid-area: lang; }
				@include r(767) {
					justify-self: left;
					padding: 0; } } } }


	.burger {
		@include r(1023) {
			display: flex;
			margin-left: auto;
			@supports(display: grid) {
				margin-left: 0;
				grid-area: burger;
				align-items: center;
				justify-self: right; } }
		@include r(767) {
			order: 2;
			padding-top: 20px;
			@supports(display: grid) {
				order: initial;
				align-items: start; } } } }
