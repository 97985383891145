.triangle {
	position: absolute;
	z-index: -1;
	max-width: 200px;
	width: 100%;
	@include r(1023) {
		display: none; }

	img {
		width: 100%; }

	&--left {
		left: 0;
		transform: rotate(180deg);
		top: auto;
		img {
			margin-right: auto; } }
	&--right {
		right: 0;
		img {
			margin-left: auto; } } }
