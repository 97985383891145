.not-robot {
	input {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 1px;
		&:checked + div {
			background-color: $violet;
			&::after {
				opacity: 1; } } }

	&__checkbox {
		position: absolute;
		left: 0;
		top: 0;
		width: 24px;
		height: 24px;
		display: block;
		border: 1px solid $violet;
		transition: background-color .25s;
		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			width: 11px;
			height: 6px;
			display: block;
			border-bottom: 3px solid $white;
			border-left: 3px solid $white;
			top: 50%;
			left: 0;
			opacity: 1;
			transition: opacity .25s;
			background-color: transparent;
			transform-origin: 5% -35%; }

		&::after {
			transform: rotate(-45deg); } }

	p {
		padding-left: 33px;
		font-size: 16px; } }
