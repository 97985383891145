// @import 'config/vars'

.site-button {
	@include bold;
	font-size: 16px;
	color: #fff;
	padding: 20px 50px;
	width: auto;
	display: inline-block;
	text-transform: uppercase;
	border: 2px solid $violet;
	line-height: 1.1em;
	cursor: pointer;
	transition: all .3s;
	background-color: $violet;
	text-align: center;
	box-sizing: border-box;
	letter-spacing: 0.05em;
	@include r(767) {
		padding: 15px 36px;
		font-size: 14px; }
	&:hover {
		border: 2px solid $violet;
		background-color: rgba($violet, .8); }
	&.is-white {
		color: $black;
		background-color: $white;
		border: 2px solid $white;
		&:hover {
			color: $white;
			background-color: $violet;
			border: 2px solid $violet; } }
	&--blue {} }
